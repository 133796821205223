import { useState } from 'react'
import { graphql } from 'gatsby'
import { useQuery, gql } from '@apollo/client'
import { Text } from '@dfds-ui/typography'
import { media, theme } from '@dfds-ui/theme'
import { Button, Headline, Spinner } from '@dfds-ui/react-components'
import { css } from '@emotion/react'
import {
  parseISO,
  format,
  fromUnixTime,
  getDayOfYear,
  setHours,
  setMinutes,
} from 'date-fns'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  BarChart,
  Bar,
  CartesianGrid,
  ReferenceLine,
  Tooltip,
  ResponsiveContainer,
  Brush,
} from 'recharts'

import { FlexCard } from '../Card'
import { EntryLink } from '../EntryLink'

const NASDAQ_CHART_LATEST_QUERY = gql`
  query NasdaqChartLatestQuery($companycode: String!) {
    chart: nasdaqChartLatest(companycode: $companycode) @client {
      ISIN
      Name
      MarketName
      Currency
      Data {
        DateTime
        Close
        Volume
      }
    }
  }
`

const NASDAQ_CHART_HISTORY_QUERY = gql`
  query NasdaqChartHistoryQuery($companycode: String!, $interval: Int!) {
    chart: nasdaqChartHistory(companycode: $companycode, interval: $interval)
      @client {
      ISIN
      Name
      MarketName
      Currency
      Data {
        Date
        Open
        Volume
        High
        Low
      }
    }
  }
`
const LoadSpinner = () => (
  <FlexCard width={{ md: 12 }}>
    <div
      css={css`
        text-align: center;
      `}
    >
      <Spinner />
    </div>
  </FlexCard>
)

const ShareChartSet = (props) => {
  const {
    barChartTitle,
    dayFilterTitle,
    lineChartSubtitle,
    lineChartTitle,
    monthsFilterTitle,
    oneMonthFilterTitle,
    oneYearFilterTitle,
    yearToDateFilterTitle,
    link,
  } = props
  const [getFilter, setFilter] = useState(0)

  const { data, loading, error } = useQuery(
    getFilter === 0 ? NASDAQ_CHART_LATEST_QUERY : NASDAQ_CHART_HISTORY_QUERY,
    {
      variables: { companycode: 'dk-dfds', interval: getFilter },
    },
  )

  if (loading) return <LoadSpinner />
  if (error) return <LoadSpinner />
  if (data) {
    const fixedTimes = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]
    const fixedTimesUnix = fixedTimes.map((time) =>
      format(setHours(setMinutes(new Date(), 0), time), 't'),
    )
    const avgVolume = Math.ceil(
      data.chart.Data.reduce(
        (total, next) => total + parseInt(next.Volume, 10),
        0,
      ) / data.chart.Data.length,
    )
    const final = data.chart.Data.map((item) => ({
      close: parseFloat(item.Close).toFixed(1),
      volume: parseInt(item.Volume, 10),
      date: item.Date && format(parseISO(item.Date), 't'),
      datetime: item.DateTime && format(parseISO(item.DateTime), 't'),
      open: parseFloat(item.Open).toFixed(1),
      high: parseFloat(item.High).toFixed(1),
      low: parseFloat(item.Low).toFixed(1),
      avgVolume,
    }))
    if (getFilter === 0) {
      final.push(
        ...fixedTimes.map((e, index) => {
          return {
            dummy: e,
            close: undefined,
            volume: undefined,
            date: undefined,
            datetime: fixedTimesUnix[index],
            open: undefined,
            high: undefined,
            low: undefined,
            avgVolume: undefined,
          }
        }),
      )
    }
    const ButtonStyle = css`
      margin-right: 10px;
      margin-bottom: 10px;

      ${media.greaterThan('m')`
        margin-bottom: 0;
      `}
    `
    const axisColor = theme.colors.text.dark.secondary
    const lineColor = theme.colors.text.secondary.primary
    const brushColor = theme.colors.text.secondary.primary

    return (
      <>
        <div
          css={css`
            ${media.greaterThan('m')`
              margin-bottom: 20px;
            `}
          `}
        >
          <Button
            variation="secondary"
            size="small"
            onClick={() => setFilter(0)}
            css={css`
              background: ${getFilter === 0 && theme.colors.secondary.dark};
              ${ButtonStyle}
            `}
          >
            {dayFilterTitle}
          </Button>
          <Button
            variation="secondary"
            size="small"
            onClick={() => setFilter(31)}
            css={css`
              background: ${getFilter === 31 && theme.colors.secondary.dark};
              ${ButtonStyle}
            `}
          >
            {oneMonthFilterTitle}
          </Button>
          <Button
            variation="secondary"
            size="small"
            onClick={() => setFilter(93)}
            css={css`
              background: ${getFilter === 93 && theme.colors.secondary.dark};
              ${ButtonStyle}
            `}
          >
            {monthsFilterTitle}
          </Button>
          <Button
            variation="secondary"
            size="small"
            onClick={() => setFilter(365)}
            css={css`
              background: ${getFilter === 365 && theme.colors.secondary.dark};
              ${ButtonStyle}
            `}
          >
            {oneYearFilterTitle}
          </Button>
          <Button
            variation="secondary"
            size="small"
            onClick={() => setFilter(getDayOfYear(new Date()))}
            css={css`
              background: ${getFilter === getDayOfYear(new Date()) &&
              theme.colors.secondary.dark};
              ${ButtonStyle}
            `}
          >
            {yearToDateFilterTitle}
          </Button>
        </div>
        <FlexCard width={{ md: 12 }}>
          <Headline as={'h2'}>{lineChartTitle}</Headline>
          <Text>{lineChartSubtitle}</Text>
          <ResponsiveContainer width="100%" height={400}>
            {getFilter === 0 ? (
              <LineChart data={final}>
                <YAxis
                  dataKey="close"
                  type="number"
                  orientation="right"
                  domain={['auto', 'auto']}
                  stroke={axisColor}
                />
                <XAxis
                  dataKey="datetime"
                  domain={[
                    fixedTimesUnix[0],
                    fixedTimesUnix[fixedTimesUnix.length - 1],
                  ]}
                  name="Time"
                  type="number"
                  scale="time"
                  ticks={fixedTimesUnix}
                  tickFormatter={(datetime) =>
                    format(fromUnixTime(datetime), 'HH:00')
                  }
                  stroke={axisColor}
                  tick={false}
                />
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <Tooltip
                  formatter={(value) => [value, 'Close']}
                  labelFormatter={(value) =>
                    format(fromUnixTime(value), 'HH:mm')
                  }
                />
                <Line type="linear" dataKey="close" stroke={lineColor} />
              </LineChart>
            ) : (
              <LineChart data={final} syncId="longperiod">
                <YAxis
                  dataKey="open"
                  type="number"
                  orientation="right"
                  domain={['auto', 'auto']}
                  stroke={axisColor}
                />
                <XAxis
                  dataKey="date"
                  domain={['dataMin', 'dataMax']}
                  tickFormatter={(date) => format(fromUnixTime(date), 'dd MMM')}
                  stroke={axisColor}
                />
                <Tooltip
                  labelFormatter={(value) =>
                    format(fromUnixTime(value), 'dd MMM')
                  }
                />
                <Line type="linear" dataKey="open" stroke={lineColor} />
              </LineChart>
            )}
          </ResponsiveContainer>
        </FlexCard>
        <FlexCard width={{ md: 12 }} action={<EntryLink {...link} />}>
          <Headline as={'h2'}>{barChartTitle}</Headline>
          <ResponsiveContainer width="100%" height={400}>
            {getFilter === 0 ? (
              <BarChart data={final}>
                <YAxis
                  dataKey="volume"
                  type="number"
                  orientation="right"
                  tick={false}
                  stroke={axisColor}
                />
                <XAxis
                  dataKey="datetime"
                  domain={['dataMin', 'dataMax']}
                  name="Time"
                  type="number"
                  scale="time"
                  ticks={fixedTimesUnix}
                  tickFormatter={(datetime) => {
                    return format(fromUnixTime(datetime), 'HH:00')
                  }}
                  stroke={axisColor}
                />
                <Tooltip
                  formatter={(value) => [value, 'Volume']}
                  labelFormatter={(value) =>
                    format(fromUnixTime(value), 'HH:mm')
                  }
                />
                <ReferenceLine
                  y={avgVolume}
                  stroke={axisColor}
                  strokeDasharray="3 3"
                  label={{
                    value: avgVolume,
                    position: 'right',
                    fill: axisColor,
                  }}
                />
                <Bar
                  dataKey="volume"
                  fill={lineColor}
                  stroke={lineColor}
                  minPointSize={5}
                />
              </BarChart>
            ) : (
              <BarChart data={final} syncId="longperiod">
                <YAxis
                  dataKey="volume"
                  type="number"
                  orientation="right"
                  tick={false}
                  stroke={axisColor}
                />
                <XAxis
                  dataKey="date"
                  domain={[0, 'dataMax']}
                  tickFormatter={(date) => format(fromUnixTime(date), 'dd MMM')}
                  stroke={axisColor}
                />
                <Brush
                  dataKey="date"
                  tickFormatter={(date) => format(fromUnixTime(date), 'dd MMM')}
                  height={15}
                  stroke={brushColor}
                />
                <Tooltip
                  labelFormatter={(value) =>
                    format(fromUnixTime(value), 'dd MMM')
                  }
                />
                <ReferenceLine
                  y={avgVolume}
                  stroke={axisColor}
                  strokeDasharray="3 3"
                  label={{
                    value: avgVolume,
                    position: 'right',
                    fill: axisColor,
                  }}
                />
                <Bar
                  dataKey="volume"
                  maxBarSize={3}
                  fill={lineColor}
                  stroke={lineColor}
                />
              </BarChart>
            )}
          </ResponsiveContainer>
        </FlexCard>
      </>
    )
  }
}

export const ShareChartSetFragment = graphql`
  fragment ShareChartSet on contentful_ShareChartSet {
    __typename
    sys {
      id
    }
    barChartTitle
    dayFilterTitle
    lineChartSubtitle
    lineChartTitle
    monthsFilterTitle
    oneMonthFilterTitle
    oneYearFilterTitle
    yearToDateFilterTitle
    link {
      ...ItemLink
    }
  }
`

export default ShareChartSet
